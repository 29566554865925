// import { getExploreName } from '../utils/platform';

// export const devConfig = {
//   sdkKey: 'Sx_iOxO4RE2piF-YibHISg',
//   sdkSecret: 'F44aLpN8awsNdyPQAbdrtKMuf8pTOOuvR65a',
//   webEndpoint: 'zoom.us',
//   topic: 'Zoom Meeting',
//   name: `${getExploreName()}-${Math.floor(Math.random() * 1000)}`,
//   password: '',
//   signature: '',
//   sessionKey: '',
//   userIdentity: '',
//   // The user role. 1 to specify host or co-host. 0 to specify participant, Participants can join before the host. The session is started when the first user joins. Be sure to use a number type.
//   role: 1
// };

let userData: any = Object.fromEntries(new URLSearchParams(location.search));

const name = `${userData.firstName} ${userData.lastName}`;
export const devConfig = {
  sdkKey: 'Sx_iOxO4RE2piF-YibHISg',
  sdkSecret: 'F44aLpN8awsNdyPQAbdrtKMuf8pTOOuvR65a',
  webEndpoint: 'zoom.us',
  topic: 'Test',
  // name: `${getExploreName()}-${Math.floor(Math.random() * 1000)}`,
  name: name,
  password: '',
  signature: '',
  sessionKey: '',
  userIdentity: '',
  // The user role. 1 to specify host or co-host. 0 to specify participant, Participants can join before the host. The session is started when the first user joins. Be sure to use a number type.
  role: userData.role !== 'HR' ? 0 : 1
  // role: 1
};
