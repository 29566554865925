// /* eslint-disable no-restricted-globals */
// import React from 'react';
// import { RouteComponentProps } from 'react-router-dom';
// import { Card, Button } from 'antd';
// import { IconFont } from '../../component/icon-font';
// import './home.scss';

// const { Meta } = Card;
// interface HomeProps extends RouteComponentProps {
//   status: string;
//   onLeaveOrJoinSession: () => void;
// }
// const Home: React.FunctionComponent<HomeProps> = (props) => {
//   const { history, status, onLeaveOrJoinSession } = props;
//   const onCardClick = (type: string) => {
//     history.push(`/${type}${location.search}`);
//   };
//   const featureList = [
//     {
//       key: 'video',
//       icon: 'icon-meeting',
//       title: 'Audio, video and share',
//       description: 'Gallery Layout, Start/Stop Audio, Mute/Unmute, Start/Stop Video, Start/Stop Screen Share'
//     },
//     {
//       key: 'chat',
//       icon: 'icon-chat',
//       title: 'Session chat',
//       description: 'Session Chat, Chat Priviledge'
//     },
//     {
//       key: 'command',
//       icon: 'icon-chat',
//       title: 'Command Channel chat',
//       description: 'Session Command Channel chat'
//     },
//     {
//       key: 'subsession',
//       icon: 'icon-group',
//       title: 'Subsession',
//       description: 'Open/Close Subsession, Assign/Move Participants into Subsession, Join/Leave Subsession'
//     },
//     {
//       key: 'preview',
//       icon: 'icon-meeting',
//       title: 'Local Preview',
//       description: 'Audio and Video preview'
//     }
//   ];
//   let actionText;
//   if (status === 'connected') {
//     actionText = 'Leave';
//   } else if (status === 'closed') {
//     actionText = 'Join';
//   }
//   return (
//     <div>
//       <div className="nav">
//         <a href="/" className="navhome">
//           <img src="./logo.svg" alt="Home" />
//           <span>VideoSDK Demo</span>
//         </a>
//         <div className="navdoc">
//           <a href="https://marketplace.zoom.us/docs/sdk/video/web/reference" target="_blank" rel="noreferrer">
//             <span>API Reference</span>
//           </a>

//           <a href="https://marketplace.zoom.us/docs/sdk/video/web/build/sample-app" target="_blank" rel="noreferrer">
//             <span>Doc</span>
//           </a>
//         </div>
//         {actionText && (
//           <Button type="link" className="navleave" onClick={onLeaveOrJoinSession}>
//             {actionText}
//           </Button>
//         )}
//       </div>

//       <div className="home">
//         <h1>Zoom Video SDK feature</h1>
//         <div className="feature-entry">
//           {featureList.map((feature) => {
//             const { key, icon, title, description } = feature;
//             return (
//               <Card
//                 cover={<IconFont style={{ fontSize: '72px' }} type={icon} />}
//                 hoverable
//                 style={{ width: 320 }}
//                 className="entry-item"
//                 key={key}
//                 onClick={() => onCardClick(key)}
//               >
//                 <Meta title={title} description={description} />
//               </Card>
//             );
//           })}
//         </div>
//       </div>
//     </div>
//   );
// };
// export default Home;

/* eslint-disable no-restricted-globals */
import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Card, Button, Form, Input } from 'antd';
import { IconFont } from '../../component/icon-font';
import './home.scss';
import './home.css';
// import Swal from 'sweetalert2';
import logo from '../../assets/images/logo.png';
const { Meta } = Card;
interface HomeProps extends RouteComponentProps {
  status: string;
  onLeaveOrJoinSession: () => void;
}
const Home: React.FunctionComponent<HomeProps> = (props) => {
  const { history, status, onLeaveOrJoinSession } = props;
  const [username, updateUsername] = useState('');
  let userData: any = Object.fromEntries(new URLSearchParams(location.search));
  console.log('🚀 ~ userData:', userData);
  const [password, updatePassword] = useState('');
  const submitUserData = async (type: string) => {
    const payload = {
      email: username,
      password: password
    };

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload)
    };

    let response = await fetch('https://api.bmgtalentplus.com.sg/auth/loginForZoom', requestOptions).then((response) =>
      response.json()
    );

    if (response?.success) {
      history.push(`/video?firstName=${userData?.firstName}&lastName=${userData.lastName}&role=${userData?.role}`, {
        apiResponse: response
      });
      localStorage.setItem('accessToken', response?.content?.accessToken);
    } else {
      // Swal.fire({
      //   icon: 'error',
      //   title: 'Oops...',
      //   text: `${response?.message}`
      // });
    }
  };
  const onCardClick = (type: any) => {
    history.push(`/${type}${location.search}`);
  };
  const featureList = [
    {
      key: 'video',
      // icon: 'icon-meeting',
      title: 'Recording'
      // description: 'Gallery Layout, Start/Stop Audio, Mute/Unmute, Start/Stop Video, Start/Stop Screen Share'
    }
  ];
  let actionText;
  if (status === 'connected') {
    actionText = 'Leave';
  } else if (status === 'closed') {
    actionText = 'Join';
  }
  return (
    <div>
      <div>
        <img src={logo} alt="" className="logo" />
      </div>
      <div className="login-page">
        <div className="login-box">
          <div>
            <Form
              name="login-form"
              initialValues={{
                remember: true
              }}
              onFinish={submitUserData}
              // onFinishFailed={onSubmitFailed}
              autoComplete="off"
            >
              <p>Login to the Dashboard</p>
              <Form.Item
                id="username"
                label="Email"
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'Please input your username!'
                  }
                ]}
              >
                <Input onChange={(e) => updateUsername(e.target.value)} />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!'
                  }
                ]}
              >
                <Input.Password onChange={(e) => updatePassword(e.target.value)} />
              </Form.Item>

              {/* <Form.Item
        name="remember"
        valuePropName="checked"
        wrapperCol={{
          offset: 8,
          span: 16,
        }}
      >
        <Checkbox>Remember me</Checkbox>
      </Form.Item> */}

              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16
                }}
              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
